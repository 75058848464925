import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch, useLocation, useHistory } from 'react-router-dom'

import dataService from 'api/data.service';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiButton from 'components/VuiButton';
import DashboardLayout from "utils/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utils/Navbars/DashboardNavbar";
import Footer from "utils/Footer";
// Overview page components
import DefaultGameCard from "utils/Cards/GameCard/DefaultGameCard";
import GameInfoCard from "utils/Cards/InfoCards/GameInfoCard";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from '@mui/icons-material/YouTube';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAmazon } from '@fortawesome/free-brands-svg-icons';

import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as NotFound } from "assets/svgs/sad.svg";
import { Typography, Grid, Backdrop, CircularProgress, Autocomplete, TextField } from '@mui/material';

function Overview() {
  const { t, i18n } = useTranslation(); // Obtiene la función de traducción t()
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const filter = searchParams.get('filter');
  const history = useHistory();

  const [gameCategories, setGameCategories] = useState([]);
  const [gameArtists, setGameArtists] = useState([]);
  const [gamePublishers, setGamePublishers] = useState([]);
  const [gameDesigners, setGameDesigners] = useState([]);

  const [filters, setFilters] = useState({
    filter:     filter || '',
    categories: searchParams.getAll('categories') || [],
    artists:    searchParams.getAll('artists') || [],
    publishers: searchParams.getAll('publishers') || [],
    designers:  searchParams.getAll('designers') || [],
    players:    searchParams.getAll('players') || '',
  }, [location.search]);

  const ZacatrusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
      <rect x="1" y="1" width="22" height="22" stroke="currentColor" strokeWidth="2" fill="none" />
      <path d="M6 6 L18 6 L6 18 L18 18" stroke="currentColor" strokeWidth="2" fill="none" />
    </svg>
  );

  const GenXIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
      <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="1" fill="none" />
      <path d="M6 6 L18 14 M6 18 L18 2" stroke="currentColor" strokeWidth="4" />
    </svg>
  );

  const KamikazeIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="18"
      height="18"
    >
      <path
        d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30 30-13.432 30-30S48.568 2 32 2zm6.016 44.508l-8.939-12.666-2.922 2.961v9.705h-5.963V17.492h5.963v11.955l11.211-11.955h7.836L33.293 29.426l12.518 17.082h-7.795"
        stroke="currentColor" strokeWidth="4"
      />
    </svg>
  );

  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handleAction = async (id, actionType) => {
    try {
      await dataService.setUserPopularity(id, actionType);
      var actionTypeWithD = "";
      if (actionType == 'like') {
        actionTypeWithD = 'liked';
      } else if (actionType == 'wish') {
        actionTypeWithD = 'wished';
      } else if (actionType == 'collect') {
        actionTypeWithD = 'collected';
      }
      setGames(prevGames =>
        prevGames.map(game => {
          if (game.id === id) {
            const newStatus = !game.status_for_user[actionTypeWithD];
            const newLikesCount = actionTypeWithD === 'liked' ? game.likes_count + (newStatus ? 1 : -1) : game.likes_count;
            const newDesiredCount = actionTypeWithD === 'wished' ? game.desired_count + (newStatus ? 1 : -1) : game.desired_count;
            const newCollectedCount = actionTypeWithD === 'collected' ? game.collected_count + (newStatus ? 1 : -1) : game.collected_count;
            return {
              ...game,
              status_for_user: {
                ...game.status_for_user,
                [actionTypeWithD]: newStatus,
              },
              likes_count: newLikesCount,
              desired_count: newDesiredCount,
              collected_count: newCollectedCount,
            };
          }
          return game;
        })
      );
    } catch (error) {
      console.error(`Error ${actionType} the game:`, error);
    }
  };

  const fetchGames = useCallback(async (page, filters) => {
    try {
      setLoading(true);
      const data = await dataService.filterGames(page, 5, filters);
      setGames(prevGames => [...prevGames, ...data.resources]);
      setHasMore(data.resources.length > 0);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  // Icon mapping for platforms
  const platformIcons = {
    facebook: <FacebookIcon />,
    instagram: <InstagramIcon />,
    X: <TwitterIcon />,
    youtube: <YouTubeIcon />,
  };

  // Function to get social links
  const getSocialLinks = (socialLinks) => {
    return socialLinks.map(link => {
      return {
        link: link.url,
        icon: platformIcons[link.platform] || null,
        color: link.platform,
      };
    });
  };

  const storeIcons = {
    amazon: <FontAwesomeIcon icon={faAmazon}/>,
    generacionx: <GenXIcon />,
    zacatrus: <ZacatrusIcon />,
    kamikaze: <KamikazeIcon />,
  };

  const getStoreLinks = (stores) => {
    return stores.map(store => {
      return {
        link: store.purchase_link,
        icon: storeIcons[store.store_name] || null,
        color: "white",
      };
    });
  };

  const clearFilters = () => {
    setFilters({
      filter:     '',
      categories: [],
      publishers: [],
      designers: [],
      artists: [],
      players: '',
    });
    setGames([]);
    setPage(1);
    setLoading(true);
    history.push({ search: '' });
  };

  const handleFilterChange = (key, value) => {
    setGames([]);
    setPage(1);
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  useEffect(() => {
    async function fetchFilters() {
      try {
        const categoriesData = await dataService.getPendingCategoriesGames();
        setGameCategories(categoriesData.resources);
        const publishersData = await dataService.getPublishers();
        setGamePublishers(publishersData.resources);
        const designersData = await dataService.getDesigners();
        setGameDesigners(designersData.resources);
        const artistsData = await dataService.getArtists();
        setGameArtists(artistsData.resources);
      } catch (error) {
        console.error('Error fetching unique values:', error);
        setError(error.message);
      }
    }
    fetchFilters();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchGames(page, filters);
  }, [page, filters, fetchGames]);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight && hasMore && !loading) {
      setPage(prevPage => prevPage + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  if (loading && page === 1) {
    return (
      <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return <div>{t('error', { error })}</div>;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={5} mb={3}>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          {filter && (
            <>
              <div style={{ marginBottom: '10px', padding: '10px', color: 'white' }}>
                {t('filteringBy')} "{filter}"
              </div>
              <br />
            </>
          )}
          <VuiBox mb={3} display="flex" alignItems="center" flexWrap="wrap" sx={{ gap: 1 }}>
            <Autocomplete
              multiple
              size="small"
              options={gameCategories}
              getOptionLabel={(option) => t(`categories.${option.name}`)}
              value={filters.categories}
              onChange={(event, value) => handleFilterChange('categories', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('categoriesLabel')}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    sx: {
                      color: 'light',
                      fontSize: '15px',
                      transform: 'none',
                      marginTop: '-35px',
                    },
                  }}
                />
              )}
              sx={{
                flex: 1,
                minWidth: '200px',
                maxWidth: '400px',
              }}
            />
            <Autocomplete
              multiple
              size="small"
              options={gameDesigners}
              value={filters.designers}
              onChange={(event, value) => handleFilterChange('designers', value)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('designersLabel')}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    sx: {
                      color: 'light',
                      fontSize: '15px',
                      transform: 'none',
                      marginTop: '-35px',
                    },
                  }}
                />
              )}
              sx={{
                flex: 1,
                minWidth: '200px',
                maxWidth: '400px',
              }}
            />
            <Autocomplete
              multiple
              size="small"
              options={gamePublishers}
              value={filters.publishers}
              onChange={(event, value) => handleFilterChange('publishers', value)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('publishersLabel')}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    sx: {
                      color: 'light',
                      fontSize: '15px',
                      transform: 'none',
                      marginTop: '-35px',
                    },
                  }}
                />
              )}
              sx={{
                flex: 1,
                minWidth: '200px',
                maxWidth: '400px',
              }}
            />
            <Autocomplete
              multiple
              size="small"
              options={gameArtists}
              value={filters.artists}
              onChange={(event, value) => handleFilterChange('artists', value)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('artistsLabel')}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    sx: {
                      color: 'light',
                      fontSize: '15px',
                      transform: 'none',
                      marginTop: '-35px',
                    },
                  }}
                />
              )}
              sx={{
                flex: 1,
                minWidth: '200px',
                maxWidth: '400px',
              }}
            />
            <Autocomplete
              options={[...Array(100).keys()].map(i => i + 1)}
              getOptionLabel={(option) => option.toString()}
              onChange={(event, value) => handleFilterChange('players', value)}
              value={filters.players}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('players')}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    sx: {
                      color: 'light',
                      fontSize: '15px',
                      transform: 'none',
                      marginTop: '-35px',
                    },
                  }}
                />
              )}
              sx={{
                flex: 1,
                minWidth: '200px',
                maxWidth: '400px',
              }}
            />
            <VuiButton
              variant="outlined"
              color="warning"
              variant="contained"
              onClick={clearFilters}
              size="small"
              sx={{
                minWidth: 'unset',
                flex: 1,
                maxWidth: '150px',
              }}
            >
              {t('clearFilters')}
            </VuiButton>
          </VuiBox>
        </Grid>
        <hr/><br/>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          {games.length > 0 ? (
            games.map((game, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={6} xl={6}>
                  <DefaultGameCard
                    image={game.image}
                    label={game.title}
                    title={game.title}
                    description={getGameDescription(game)}
                    actions={[
                      {
                        type: "internal",
                        route: `/game/${game.id}`,
                        color: "light",
                        label: t('see'),
                        icon: <InfoIcon />,
                      },
                      {
                        type: "internal",
                        route: `#`,
                        color: game.status_for_user.liked ? "error" : 'info',
                        label: game.status_for_user.liked ? t('unLikeMe') : t('likeMe'),
                        icon: <FavoriteIcon />,
                        onClick: () => handleAction(game.id, 'like'),
                      },
                      {
                        type: "internal",
                        route: `#`,
                        color: game.status_for_user.wished ? "error" : 'warning',
                        label: game.status_for_user.wished ? t('unWishGame') : t('wishGame'),
                        icon: <BookmarkIcon />,
                        onClick: () => handleAction(game.id, 'wish'),
                      },
                      {
                        type: "internal",
                        route: `#`,
                        color: game.status_for_user.collected ? "error" : 'success',
                        label: game.status_for_user.collected ? t('doesNotHaveIt') : t('haveIt'),
                        icon: <CheckCircleIcon />,
                        onClick: () => handleAction(game.id, 'collect'),
                      },
                      {
                        type: "external",
                        route: `https://www.google.com/search?q=${game.title}`,
                        color: "dark",
                        label: t('moreInfo'),
                      }
                    ]}
                    options={[]}
                  />
                </Grid>
                <Grid item xs={12} md={8} xl={6}>
                  <GameInfoCard
                    image=""
                    title={game.title}
                    gameId={game.id}
                    description=""
                    info={{
                      [t('ageRecommendation')]: game.age_recommendation,
                      [t('artist')]: game.get_artists || 'N/A',
                      [t('designer')]: game.get_designers || 'N/A',
                      [t('publisher')]: game.get_publishers || 'N/A',
                      [t('players')]: `${game.min_players}/${game.max_players}`,
                      [t('time')]: `${game.min_playtime}/${game.max_playtime} min.`,
                      [t('yearPublished')]: game.year_published || 'N/A',
                      [t('categoriesLabel')]: game.get_categories || 'N/A',
                      [t('rank')]: game.rank || 'N/A',
                      [t('popularity')]: ( <span>
                            {game.likes_count}
                            <FavoriteIcon style={{ color: 'red', marginRight: '5px' }} />
                            {game.desired_count}
                            <BookmarkIcon style={{ color: 'blue', marginRight: '5px' }} />
                            {game.collected_count}
                            <CheckCircleIcon style={{ color: 'green', marginRight: '5px' }} />
                      </span>
                      ),
                    }}
                    social={getSocialLinks(game.social_links)}
                    sellers={getStoreLinks(game.stores)}
                  />
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <VuiBox mt={12} textAlign="center" xs={12} md={12} xl={12} color="light">
              <NotFound style={{ width: '55%', height: '55%' }} />
              <Typography variant="h3" sx={{ color: 'white !important' }}>{t('noGameData')}</Typography>
            </VuiBox>
          )}
        </Grid>
      </VuiBox>
      {loading && <VuiBox mt={12} textAlign="center" xs={12} md={12} xl={12} color="light"><CircularProgress color="inherit" /></VuiBox>}
      <Footer />
    </DashboardLayout>
  );
  function getGameDescription(game) {
    const currentLanguage = i18n.language;
    if (!game.translations || game.translations.length === 0) {
      return game.description;
    }
    const translation = game.translations.find(trans => trans.language === currentLanguage);
    return translation ? translation.translated_description : game.description;
  }
}
export default Overview;
