import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import md5 from 'md5';
import dataService from 'api/data.service';
import { AuthContext } from "context/AuthContext";

// @mui material components
import { Grid, CircularProgress, Backdrop, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import VuiButton from 'components/VuiButton';
import VuiAvatar from "components/VuiAvatar";
import DashboardLayout from "utils/LayoutContainers/DashboardLayout";
import Footer from "utils/Footer";
import Header from "pages/profile/components/Header";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from '@mui/icons-material/YouTube';
import DeleteIcon from '@mui/icons-material/Delete';
import CountrySelect from "components/CustomSelects/CountrySelect";

function Overview() {
  const { t } = useTranslation();
  const [profile, setProfile] = useState({
    display_name: '',
    country: '',
    city: '',
    birthday: '',
    about_me: '',
    phone: '',
    social_links: {
      facebook: '',
      instagram: '',
      twitter: '',
      youtube: '',
    },
    password: '',
    confirm_password: '',
    avatar: '',
  });
  const [loading, setLoading] = useState(true);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState(null);
  const [openAvatarDialog, setOpenAvatarDialog] = useState(false);
  const { logout } = useContext(AuthContext);
  const avatarContext = require.context('assets/images/avatars', false, /\.(png)$/);
  const avatarFiles = avatarContext.keys();
  const avatars = avatarFiles.map((filename) => {
    const name = filename
      .replace('./', '')
      .replace('.png', '');
    const cleanName = name.startsWith('assets/images/avatars/') 
      ? name.replace('assets/images/avatars/', '') 
      : name;
    const resource = avatarContext(filename);
    const src = typeof resource === 'string' ? resource : resource.default || '';
    return {
      src: src.startsWith('data:image') ? '' : src,
      name: cleanName,
    };
  }).filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.name === item.name
    ))
  );

  const getGravatarUrl = (email) => {
    const hash = md5(email.trim().toLowerCase());
    return `https://www.gravatar.com/avatar/${hash}?s=200&d=identicon`;
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await dataService.getSessionProfile();
        const profileData = data.resource;
        setProfile({
          ...profileData,
          birthday: profileData.birthday,
          social_links: {
            facebook: profileData.social?.find(link => link.platform === 'facebook')?.url || '',
            instagram: profileData.social?.find(link => link.platform === 'instagram')?.url || '',
            twitter: profileData.social?.find(link => link.platform === 'twitter')?.url || '',
            youtube: profileData.social?.find(link => link.platform === 'youtube')?.url || '',
          },
          avatar: profileData.avatar || '',
        });
      } catch (err) {
        setOpenErrorDialog(true);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return '';  
    const parts = dateString.split('-');
    if (parts.length === 3 && parts[0].length === 4 && parts[1].length === 2 && parts[2].length === 2) {
      return parts.reverse().join('-');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleAvatarChange = (e) => {
    const selectedAvatarName = e.target.value;
    const avatarUrl = selectedAvatarName === 'gravatar' ? getGravatarUrl(profile.email) : getAvatarSrc(selectedAvatarName);
    setProfile((prevProfile) => ({
      ...prevProfile,
      avatar: avatarUrl,
    }));
    setOpenAvatarDialog(false);
  };

  const handleOpenAvatarDialog = () => {
    setOpenAvatarDialog(true); // Abre el diálogo de avatares
  };

  const handleSocialLinkChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      social_links: {
        ...prevProfile.social_links,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (profile.password !== profile.confirm_password) {
      setOpenPasswordDialog(true);
      return;
    }
    const currentBirthday = profile.birthday
    try {
      setLoading(true);
      profile.birthday = formatDate(profile.birthday);
      await dataService.updateUserProfile(profile);
      profile.birthday = currentBirthday;
      setErrorDialogMessage(t('profileUpdatedSuccessfully'));
      setOpenErrorDialog(true);
    } catch (error) {
      profile.birthday = currentBirthday;
      setErrorDialogMessage(error.message);
      setOpenErrorDialog(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    setOpenDeleteDialog(true);
  };

  const confirmDeleteAccount = async () => {
    try {
      await dataService.deleteUserAccount();
      setErrorDialogMessage(t('accountDeletedSuccessfully'));
      setOpenErrorDialog(true);
      await logout();
    } catch (error) {
      setError(error.message);
      setErrorDialogMessage(error.message);
      setOpenErrorDialog(true);
    }
    setOpenDeleteDialog(false);
  };

  const handleCountryChange = (selectedCountry) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      country: selectedCountry,
    }));
  };

  const getAvatarSrc = (avatarName) => {
    try {
      // Intenta requerir el avatar
      return avatarContext(`./${avatarName}.png`);
    } catch (error) {
      // Si hay un error, devuelve la imagen predeterminada
      return profile.avatar;
    }
  };

  if (loading) {
    return (
      <Backdrop open={loading} style={{ zIndex: 10000, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    return <div>{t('error', { error })}</div>;
  }

  return (
    <DashboardLayout>
      <Header />
      <VuiBox mt={5} mb={3}>
        <VuiBox mb={3}>
          <form onSubmit={handleSubmit}>
            <VuiBox mt={3}>
              <VuiButton variant="contained" color="success" type="submit">
                {t('saveChanges')}
              </VuiButton>
            </VuiBox>
            <br/>

            <Grid container spacing={3}>
              {/* Resto de los campos del formulario */}
              <Grid item xs={12} md={6}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('name')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="name"
                  value={profile.name}
                  onChange={handleChange}
                  autocomplete="off"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('displayName')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="display_name"
                  value={profile.display_name}
                  onChange={handleChange}
                  autocomplete="off"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('country')}
                </VuiTypography>
                <CountrySelect
                  name="country"
                  onChange={handleCountryChange}
                  value={profile.country}
                  styles={{ color: "black" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('city')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="city"
                  value={profile.city}
                  onChange={handleChange}
                  autocomplete="off"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('birthday')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="birthday"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={profile.birthday}
                  onChange={handleChange}
                  sx={{
                    '& input': {
                      color: 'white',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#001540',
                      },
                      '&:hover fieldset': {
                        borderColor: '#001540',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#001540',
                      },
                    },
                    '& .MuiInputAdornment-root': {
                      color: 'white !important',
                    },
                    '& input[type="date"]::-webkit-calendar-picker-indicator': {
                      filter: 'invert(1)',  // Cambia el color del icono a blanco
                    },
                    '& input[type="date-local"]::-webkit-calendar-picker-indicator': {
                      filter: 'invert(1)',  // Cambia el color del icono a blanco
                    },
                    '& input::placeholder': {
                      color: 'white',
                      opacity: 1,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('aboutMe')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="about_me"
                  value={profile.about_me}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  autocomplete="off"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('phone')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="phone"
                  value={profile.phone}
                  onChange={handleChange}
                  autocomplete="off"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('facebook')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="facebook"
                  value={profile.social_links.facebook}
                  onChange={handleSocialLinkChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon />
                      </InputAdornment>
                    ),
                  }}
                  autocomplete="off"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('instagram')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="instagram"
                  value={profile.social_links.instagram}
                  onChange={handleSocialLinkChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InstagramIcon />
                      </InputAdornment>
                    ),
                  }}
                  autocomplete="off"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('twitter')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="twitter"
                  value={profile.social_links.twitter}
                  onChange={handleSocialLinkChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TwitterIcon />
                      </InputAdornment>
                    ),
                  }}
                  autocomplete="off"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('youTube')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="youtube"
                  value={profile.social_links.youtube}
                  onChange={handleSocialLinkChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <YouTubeIcon />
                      </InputAdornment>
                    ),
                  }}
                  autocomplete="off"
                />
              </Grid>
            </Grid>
            <br /><hr /><br />
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={4}>
                <VuiAvatar
                  src={getAvatarSrc(profile.avatar)}
                  alt="profile-image"
                  variant="rounded"
                  size="xl"
                  shadow="sm"
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <VuiBox display="flex" alignItems="center" justifyContent="flex-start" flexDirection="column" ml={2}>
                  <VuiTypography variant="body2" fontWeight="bold" color="light">
                    {t('selectAvatar')}
                  </VuiTypography>
                  <VuiButton variant="contained" color="warning" onClick={handleOpenAvatarDialog} mt={1}>
                    {t('chooseAvatar')}
                  </VuiButton>
                </VuiBox>
              </Grid>
            </Grid>
            <br/><hr/><br/>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('newPassword')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="password"
                  type="password"
                  value={profile.password}
                  onChange={handleChange}
                  autocomplete="new-password"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <VuiTypography variant="body2" fontWeight="bold" color="light">
                  {t('confirmPassword')}
                </VuiTypography>
                <VuiInput
                  fullWidth
                  name="confirm_password"
                  type="password"
                  value={profile.confirm_password}
                  onChange={handleChange}
                  autocomplete="new-password"
                />
              </Grid>
            </Grid>
          </form>
          <br/>
          <hr/>
          <br/>
          <VuiBox mt={3}>
            <VuiButton
              variant="contained"
              size="small"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteAccount}
            >
              {t('deleteAccount')}
            </VuiButton>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      <Footer />

      <Dialog
        open={openAvatarDialog}
        onClose={() => setOpenAvatarDialog(false)}
      >
        <DialogTitle>{t('selectAvatar')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={3} key="gravatar">
              <FormControlLabel
                control={
                  <Radio
                    checked={profile.avatar === "gravatar"}
                    onChange={handleAvatarChange}
                    value="gravatar"
                  />
                }
                label={<img src={getGravatarUrl(profile.email)} alt="gravatar" style={{ width: '100%', height: 'auto' }} />}
              />
            </Grid>
            {avatars.map((avatar) => (
              <Grid item xs={4} sm={3} key={avatar.name}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={profile.avatar === avatar.name}
                      onChange={handleAvatarChange}
                      value={avatar.name}
                    />
                  }
                  label={<img src={avatar.src} alt={avatar.name} style={{ width: '100%', height: 'auto' }} />}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={() => setOpenAvatarDialog(false)} color="primary" variant="contained">
            {t('close')}
          </VuiButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPasswordDialog}
        onClose={() => setOpenPasswordDialog(false)}
      >
        <DialogTitle>{t('error', { error })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('passwordsDoNotMatch')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={() => setOpenPasswordDialog(false)} color="primary" variant="contained">
            {t('close')}
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Dialogo de confirmación de eliminación de cuenta */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>{t('deleteAccountConfirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('confirmDeleteAccount')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={() => setOpenDeleteDialog(false)} color="primary" variant="contained">
            {t('cancel')}
          </VuiButton>
          <VuiButton onClick={confirmDeleteAccount} color="error" variant="contained">
            {t('deleteAccount')}
          </VuiButton>
        </DialogActions>
      </Dialog>

      {/* Dialogo de mensaje de error */}
      <Dialog
        open={openErrorDialog}
        onClose={() => setOpenErrorDialog(false)}
      >
        <DialogTitle>{t('notification')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {errorDialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={() => setOpenErrorDialog(false)} color="primary" variant="contained">
            {t('close')}
          </VuiButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Overview;
