// BackButton.js
import React from 'react';
import { useHistory } from 'react-router-dom';
import VuiButton from "components/VuiButton";
import { useTranslation } from 'react-i18next';

const BackButton = ({ label = 'Retroceder' }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleBack = () => {
    history.goBack();
  };

  const showButton = history.length > 1 && location.pathname !== '/dashboard';
  if (!showButton) return null;

  return (
    <VuiButton
      onClick={handleBack}
      size="small"
      variant="contained" color="primary"
      sx={{
        maxWidth: '15px !important',
        marginLeft: '0px !important',
        marginRight: '30px !important'
      }}
    >
      <span className="back-icon">&lt;&lt;</span>
    </VuiButton>
  );
};

export default BackButton;
